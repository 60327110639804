const invoices = [
  {
    path: '/subscriptions/active',
    name: 'active-subscriptions',
    component: () => import('@/views/subscriptions/SubscriptionsList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default invoices
