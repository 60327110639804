import { mdiPalette } from '@mdi/js'

export default [
  {
    subheader: 'Appearance',
  },
  {
    title: 'Backgrounds',
    icon: mdiPalette,
    to: 'appearance-backgrounds',
  },
]
