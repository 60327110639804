import { mdiPalette } from '@mdi/js'

export default [
  {
    subheader: 'Аудио медитации',
  },
  {
    title: 'Программы',
    icon: mdiPalette,
    to: 'audio-meditations-programs',
  },
  {
    title: 'Темы',
    icon: mdiPalette,
    to: 'audio-meditations-topics',
  },
  {
    title: 'Медитации',
    icon: mdiPalette,
    to: 'audio-meditations-meditations',
  },
]
