import store from '@/store'
import axios from '@axios'
import router from '@/router'

export default function userModel() {
  const profile = async () => {
    let profileData = store.state.user.profile
    if (!profileData) {
      await axios
        .get('/user/profile/me')
        .then(response => {
          store.commit('user/SET_PROFILE_MODEL', response.data.user)
          profileData = store.state.user.profile
        })
        .catch(() => router.push('/login'))
    }

    return profileData
  }

  return {
    profile,
  }
}
