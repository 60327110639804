const settings = [
  {
    path: '/avatars/colors',
    name: 'avatars-colors',
    component: () => import('@/views/avatars/colors/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/avatars/emojis',
    name: 'avatars-emojis',
    component: () => import('@/views/avatars/emojis/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/avatars/emoji-groups',
    name: 'avatars-emoji-groups',
    component: () => import('@/views/avatars/emoji-groups/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/avatars/galleries',
    name: 'avatars-galleries',
    component: () => import('@/views/avatars/galleries/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/avatars/gallery-items',
    name: 'avatars-gallery-items',
    component: () => import('@/views/avatars/gallery-items/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/avatars/gradients',
    name: 'avatars-gradients',
    component: () => import('@/views/avatars/gradients/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/avatars/icons',
    name: 'avatars-icons',
    component: () => import('@/views/avatars/icons/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default settings
