const analytics = [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/UsersList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/workspaces',
    name: 'workspaces',
    component: () => import('@/views/workspaces/WorkspacesList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/analytics/integrations',
    name: 'analytics-integrations',
    component: () => import('@/views/analytics/integrations/Integrations.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default analytics
