export default {
  namespaced: true,
  state: {
    profile: null,
  },
  getters: {},
  mutations: {
    SET_PROFILE_MODEL(state, value) {
      state.profile = value
    },
  },
  actions: {},
}
