const settings = [
  {
    path: '/audio-meditations/programs',
    name: 'audio-meditations-programs',
    component: () => import('@/views/audio-meditations/programs/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/audio-meditations/topics',
    name: 'audio-meditations-topics',
    component: () => import('@/views/audio-meditations/topics/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/audio-meditations/meditations',
    name: 'audio-meditations-meditations',
    component: () => import('@/views/audio-meditations/meditations/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default settings
