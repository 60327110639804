const settings = [
  {
    path: '/settings/promo-codes',
    name: 'settings-promo-codes',
    component: () => import('@/views/settings/promoCodes/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/settings/admins',
    name: 'settings-admins',
    component: () => import('@/views/settings/admins/AdminList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/help/stories',
    name: 'help-stories',
    component: () => import('@/views/help/stories/List.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/settings/telegram-mailings',
    name: 'settings-telegram-mailings',
    component: () => import('@/views/settings/telegramMailings/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default settings
