const routes = [
  {
    path: '/ai/help-bot-messages',
    name: 'ai-help-bot-messages',
    component: () => import('@/views/ai/help-bot-messages/Table.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/ai/text-dataset',
    name: 'ai-text-dataset',
    component: () => import('@/views/ai/text-dataset/Table.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/ai/voice-dataset',
    name: 'ai-voice-dataset',
    component: () => import('@/views/ai/voice-dataset/Table.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/ai/youtube-exports',
    name: 'ai-youtube-exports',
    component: () => import('@/views/ai/youtube-exports/Panel.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default routes
