import { mdiPercentOutline } from '@mdi/js'

export default [
  {
    subheader: 'ПОДПИСКИ',
  },
  {
    title: 'Активные подписки',
    icon: mdiPercentOutline,
    to: 'active-subscriptions',
  },
]
