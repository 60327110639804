import {
  mdiCat,
  mdiEmoticonCool,
  mdiFolderMultipleImage, mdiGradientVertical, mdiImage, mdiImageEditOutline,
  mdiPalette,
} from '@mdi/js'

export default [
  {
    subheader: 'AVATARS',
  },
  {
    title: 'Colors',
    icon: mdiPalette,
    to: 'avatars-colors',
  },
  {
    title: 'Emojis groups',
    icon: mdiEmoticonCool,
    to: 'avatars-emoji-groups',
  },
  {
    title: 'Emojis',
    icon: mdiCat,
    to: 'avatars-emojis',
  },
  {
    title: 'Galleries',
    icon: mdiImage,
    to: 'avatars-galleries',
  },
  {
    title: 'Gallery items',
    icon: mdiFolderMultipleImage,
    to: 'avatars-gallery-items',
  },
  {
    title: 'Gradients',
    icon: mdiGradientVertical,
    to: 'avatars-gradients',
  },
  {
    title: 'icons',
    icon: mdiImageEditOutline,
    to: 'avatars-icons',
  },
]
