import '@/styles/styles.scss'

import Vue from 'vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// eslint-disable-next-line import/no-extraneous-dependencies
// import DatetimePicker from 'vuetify-datetime-picker'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
// eslint-disable-next-line import/no-extraneous-dependencies
// import VueLuxon from 'vue-luxon'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

import './modules/markdown'

// Vue.use(VueLuxon)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg',
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
