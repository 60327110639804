const settings = [
  {
    path: '/appearance/backgrounds',
    name: 'appearance-backgrounds',
    component: () => import('@/views/appearance/backgrounds/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default settings
