import Vue from 'vue'
import markdownit from 'markdown-it'

const markdown = markdownit({
  html: true,
  linkify: true,
  typographer: true,
})

Object.defineProperties(Vue.prototype, {
  $markdown: {
    get() {
      return markdown
    },
  },
})
