const routes = [
  {
    path: '/geolocation/countries',
    name: 'geolocation-countries',
    component: () => import('@/views/geolocation/countries/List.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default routes
