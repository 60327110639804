import { mdiEarth } from '@mdi/js'

export default [
  {
    subheader: 'GEO',
  },
  {
    title: 'Страны',
    icon: mdiEarth,
    to: 'geolocation-countries',
  },
]
